<template>
  <b-row no-gutters v-if="systemFields" cols="2">
    <b-col v-if="showCreated">
      <b-row>
        <b-col class="label" md="4">
          <strong class="small"> {{ 'created_by' | snakeToTitle }}: </strong>
        </b-col>
        <b-col class="content" md="8">
          <small>{{ systemFields.created_by }}</small>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="showUpdated">
      <b-row>
        <b-col class="label" md="4">
          <strong class="small"> {{ 'updated_by' | snakeToTitle }}: </strong>
        </b-col>
        <b-col class="content" md="8">
          <small>{{ systemFields.updated_by }}</small>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="showCreated">
      <b-row>
        <b-col class="label" md="4">
          <strong class="small"> {{ 'created_on' | snakeToTitle }}: </strong>
        </b-col>
        <b-col class="content" md="8">
          <small>{{ systemFields.created_on | moment('L LTS') }}</small>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="showUpdated">
      <b-row>
        <b-col class="label" md="4">
          <strong class="small"> {{ 'updated_on' | snakeToTitle }}: </strong>
        </b-col>
        <b-col class="content" md="8">
          <small>{{ systemFields.updated_on | moment('L LTS') }}</small>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import '@/dependencies/vue_moment_dependencies'

export default {
  name: 'SystemFields',
  props: {
    systemFields: {
      type: Object,
      default: Object
    },
    showUpdated: {
      type: Boolean,
      default: true
    },
    showCreated: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.label {
  display: flex;
  align-items: center;
}
.content {
  display: flex;
  align-items: flex-start;
}
</style>
